<template>
  <div class="history" style="display: block">
    <div class="item_box">
      <div class="item"><span>我玩过的</span></div>
    </div>
    <div class="game_box">
      <div
        class="swiper-container_wg swiper-container swiper-container-horizontal"
      >
        <swiper
          class="swiper-wrapper_wg"
          :slides-per-view="5"
          :space-between="0"
        >
          <!-- <template v-for="(item, index) in 5" v-bind:key="index"> -->
          <!-- </template> -->
            <swiper-slide
              class="swiper-wrapper_wg"
              v-for="(item, index) in usergames"
              :key="index"
            >
              <div class="swiper-slide_wg">
                <a v-bind:href="item.link" class="enter mustlogin" v-bind:data-gid="item.gid">
                  <div class="icon">
                    <img
                      v-bind:src="item.image"
                    />
                  </div>
                  <div class="name"><span>{{item.name}}</span></div>
                </a>
              </div>
            </swiper-slide>



        </swiper>

        <!-- <div class="swiper-wrapper_wg swiper-wrapper">
          <div
            class="swiper-slide_wg swiper-slide swiper-slide-active"
            style="width: 100px"
          >
            <a
              href="/server/axjh-1.html"
              class="enter mustlogin"
              data-gid="7172"
            >
              <div class="icon">
                <img
                  src="http://res.lequ.com/Public/Uploads/202101/600a7588d1f2c.png"
                />
              </div>
              <div class="name"><span>傲笑江湖</span></div>
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

import { usergame } from "@/api/user";

import { mapState } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      usergames: {},
    };
  },
  computed: {
    ...mapState(["user"]), // 将全局数据，映射为计算属性
  },
  methods: {
    loadUserHistoryGame: async function () {
      let param = { token: this.user };
      // console.log(param);

      const { data } = await usergame(param);
      if (data.code == "200") {
        this.usergames=data.data
        console.log(data.data);
      } else {
        console.log("失败");
      }
    },
  },
  created() {
    this.loadUserHistoryGame();
  },
};
</script>

<style scoped>
.history {
  padding: 0;
  width: 100%;
  height: 230px;
  background: #ffffff;
  display: none;
}
.history .item_box {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e4e2e3;
  background: #efeef4;
}
.history .item_box .item {
  height: 100%;
  float: left;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 28px;
}
.history .item_box .item img {
  height: 20px;
  margin: 6px 10px;
  float: left;
}
.history .item_box .item span {
  font-size: 28px;
  height: 100%;
  line-height: 50px;
  vertical-align: middle;
  color: #4a4848;
}

.history .game_box {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 180px;
  overflow: hidden;
}
.history .game_box .swiper-container_wg {
  left: 0;
  width: 100%;
  height: 100%;
}
.history .game_box .swiper-container_wg .swiper-wrapper_wg .swiper-slide_wg {
  width: 150px;
  height: 100%;
}
.history
  .game_box
  .swiper-container_wg
  .swiper-wrapper_wg
  .swiper-slide_wg
  .icon {
  display: inline-block;
  margin-top: 20px;
  width: 100%;
  height: 100px;
  text-align: center;
}
.history
  .game_box
  .swiper-container_wg
  .swiper-wrapper_wg
  .swiper-slide_wg
  .icon
  img {
  width: 100px;
  border-radius: 10px;
  margin: 0px auto;
}
.history
  .game_box
  .swiper-container_wg
  .swiper-wrapper_wg
  .swiper-slide_wg
  .name {
  display: block;
  margin-top: 0px;
  width: 100%; /*text-align: center;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.history
  .game_box
  .swiper-container_wg
  .swiper-wrapper_wg
  .swiper-slide_wg
  .name
  span {
  display: block;
  width: 100%;
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
}
</style>