<template>
  <top></top>

  <banner v-model:list="list"></banner>

  <div id="game_con" class="main_1_1">
    <!--玩过的游戏start-->
    <history v-if="user"></history>
    <!--玩过的游戏end-->

    <gamelist
      v-model:pushGameList="pushGameList"
      v-model:newGmaeList="newGmaeList"
      v-on:resetPageComponent="resetPage"
      ref="ch"
    ></gamelist>
    <div class="loading" style="text-align: center; line-height: 2">
      <span>{{ loading ? "正在加载中..." : "已经到底啦" }}</span>
    </div>
  </div>
  <div
    style="font-size: 12px; color: #666666; text-align: center; line-height: 2"
  >
    Copyright © 2009-2021 XXXXXXX有限公司<br />
    互联网ICP备案：XB2-XXXXXXXX<br />
    公安部备案号XXXXXXXXXXX号<br />抵制不良游戏 拒绝盗版游戏 注意自我保护
    谨防受骗上当<br />适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活<br />禁止利用网络游戏进行赌博活动
  </div>
  <fnav></fnav>
</template>

<script>
import { mapState } from "vuex";

import top from "@/components/top.vue";
import banner from "@/components/banner.vue";
import history from "@/components/history.vue";
import gamelist from "@/components/gamelist.vue";
import fnav from "@/components/fnav.vue";

import { getGameList } from "@/api/game";

export default {
  name: "HomeIndex",
  components: {
    top,
    banner,
    history,
    gamelist,
    fnav,
  },
  data() {
    return {
      // user: "",
      pushGameList: [],
      newGmaeList: [],
      loading: false,
      page: 1,
    };
  },
  computed: {
    ...mapState(["user"]),
    // this.user=this.$store.state.user
  },
  methods: {
    resetPage: function () {
      this.page = 1;
      // this.pushGameList=[]
      // this.newGmaeList=[]
      // console.log('回来了');
    },
    showSubscribe: function () {
      this.ok = true;
    },
    closeSubscribe: function () {
      this.ok = false;
    },
    //去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },

    loadPushGameList: async function () {
      // let vm = this
      const pushParam = { flag: "push", page: this.page };
      const { data } = await getGameList(pushParam);

      if (data.code == "200") {
        if (this.page > 1) {
          // ...添加

          this.pushGameList.push(...data.data);
          this.pushGameList = this.unique(this.pushGameList);
          // this.pushGameList=this.select(this.pushGameList);

          this.loading = true;
        } else if (this.page == 1) {
          this.pushGameList = data.data;
        }
      } else {
        this.loading = false;
      }
    },
    loadNewGameList: async function () {
      const newParam = { flag: "new", page: this.page };
      const { data } = await getGameList(newParam);
      if (data.code == "200") {
        if (this.page > 1) {
          this.newGmaeList.push(...data.data);
          this.newGmaeList = this.unique(this.newGmaeList);

          this.loading = true;
        } else if (this.page == 1) {
          this.newGmaeList = data.data;
        }
      } else {
        this.loading = false;
      }
    },
    scrollFn: function () {
      let winHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      let st =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let docHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      if (winHeight + st >= docHeight) {
        console.log("触底了");

        //$refs.ch获取子组件 变量
        if (this.$refs.ch.shownav == 0) {
          this.page++;
          console.log("第" + this.page + "页");
          this.loadPushGameList();
        } else if (this.$refs.ch.shownav == 1) {
          this.page++;
          console.log("第" + this.page + "页");
          this.loadNewGameList();
        }
      }
    },
  },
  created() {
    this.loadPushGameList();
    this.loadNewGameList();
  },
  setup() {
    return {
      // showuserimg: false,
      // showlogo: true,
      list: [
        { id: 1, pic: "@/assets/images/banner/banner1.jpg" },
        { id: 2, pic: "@/assets/images/banner/banner2.jpg" },
        { id: 3, pic: "@/assets/images/banner/banner3.jpg" },
        { id: 4, pic: "~@/assets/images/banner/banner4.jpg" },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollFn);
  },
};
</script>

<style>
</style>