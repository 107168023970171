<template>
  <!--选项卡-->
  <div id="nav" class="nav">
    <a
      href="#tj"
      id="nav_tj"
      v-bind:class="shownav == 0 ? 'on' : ''"
      v-on:click="showgame('tj')"
      >推荐</a
    >
    <a
      href="#xsj"
      id="nav_xsj"
      v-bind:class="shownav == 1 ? 'on' : ''"
      v-on:click="showgame('xsj')"
      >新上架</a
    >
    <a
      href="#xyx"
      id="nav_xyx"
      v-bind:class="shownav == 2 ? 'on' : ''"
      v-on:click="showgame('xyx')"
      >小游戏</a
    >
    <a
      href="#xkf"
      id="nav_xkf"
      v-bind:class="shownav == 3 ? 'on' : ''"
      v-on:click="showgame('xkf')"
      >新开服<em class="dot"></em
    ></a>
  </div>

  <div class="navcon">
    <div class="columns">
      <div class="tj_con" v-show="shownav == 0">
        <li
          class="hengtiao_game"
          v-for="(item, index) in pushGameList"
          :key="index"
        >
          <div class="game_box">
            <div class="icon pr">
              <i class="jp pa" v-if="item.flag.includes('jp')">精品</i>
              <a v-bind:href="'/gamedetail/' + item.id">
                <img v-bind:src="item.icon" />
              </a>
            </div>
            <div class="detail">
              <a v-bind:href="'/gamedetail/' + item.id">
                <div class="name">
                  <span>{{ item.name }}</span>
                  <div class="label">
                    <i
                      style="color: #ffffff; background: #5ac751"
                      v-if="item.flag.includes('new')"
                      >新游</i
                    ><i
                      style="color: #ffffff; background: #c100ff"
                      v-if="item.flag.includes('push')"
                      >推荐</i
                    ><i
                      style="color: #ffffff; background: #fdc100"
                      v-if="item.flag.includes('hot')"
                      >热门</i
                    >
                  </div>
                </div>
                <div class="msg">{{ item.description }}</div>
              </a>
            </div>
            <div class="button">
              <a class="mustlogin" v-bind:href="item.link">开始玩</a>
            </div>
          </div>
        </li>

        <!-- <li class="hengtiao_game">
          <div class="game_box">
            <div class="icon pr">
              <i class="jp pa">精品</i>
              <a href="#gamedetail_7078">
                <img
                  src="http://res.lequ.com/Public/Uploads/202007/5efc54ed0c9c4.png"
                />
              </a>
            </div>
            <div class="detail">
              <a href="#gamedetail_7078">
                <div class="name">
                  <span>吞食之刃</span>
                  <div class="label">
                    <i style="color: #ffffff; background: #5ac751">新游</i
                    ><i style="color: #ffffff; background: #c100ff">推荐</i
                    ><i style="color: #ffffff; background: #fdc100">热门</i>
                  </div>
                </div>
                <div class="msg">创角完成国家选择时，战争就已经悄然打响！</div>
              </a>
            </div>
            <div class="button">
              <a class="mustlogin" href="#">开始玩</a>
            </div>
          </div>
        </li> -->
      </div>

      <div class="xsj_con" v-show="shownav == 1">
        <li
          class="hengtiao_game"
          v-for="(item, index) in newGmaeList"
          :key="index"
        >
          <div class="game_box">
            <div class="icon pr">
              <i class="jp pa" v-if="item.flag.includes('jp')">精品</i>
              <a v-bind:href="'/gamedetail/' + item.id">
                <img v-bind:src="item.icon" />
              </a>
            </div>
            <div class="detail">
              <a v-bind:href="'/gamedetail/' + item.id">
                <div class="name">
                  <span>{{ item.name }}</span>
                  <div class="label">
                    <i
                      style="color: #ffffff; background: #5ac751"
                      v-if="item.flag.includes('new')"
                      >新游</i
                    >
                    <i
                      style="color: #ffffff; background: #c100ff"
                      v-if="item.flag.includes('push')"
                      >推荐</i
                    >
                    <i
                      style="color: #ffffff; background: #fdc100"
                      v-if="item.flag.includes('hot')"
                      >热门</i
                    >
                  </div>
                </div>
                <div class="msg">{{ item.description }}</div>
              </a>
            </div>
            <div class="button">
              <a class="mustlogin" v-bind:href="item.link">开始玩</a>
            </div>
          </div>
        </li>
      </div>
      <div class="xyx_con" v-show="shownav == 2">
        <li>小游戏</li>
      </div>
      <div class="xkf_con" v-show="shownav == 3">
        <li>新开服</li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pushGameList: {},
    newGmaeList: {},
  },
  emits: {
    resetPageComponent: {},
  },
  data() {
    return {
      shownav: 0,
    };
  },

  methods: {
    changePage: function () {
      this.$emit("resetPageComponent");
    },
    showgame(obj) {
      this.changePage()
      switch (obj) {
        case "tj":
          this.shownav = 0;
          break;
        case "xsj":
          this.shownav = 1;
          break;
        case "xyx":
          this.shownav = 2;
          break;
        case "xkf":
          this.shownav = 3;
          break;
      }

    },
  },

  created() {
    // console.log(this.gameInfoList);
  },
};
</script>

<style>
.nav {
  width: 100%;
  height: 88px;
  display: flex;
  margin-bottom: 1px;
  padding: 0;
  background: #ffffff;
  border-top: 1px solid #e2dfdf;
  border-bottom: 1px solid #e2dfdf;
}
.nav a {
  display: block;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  text-align: center;
  text-decoration: none;
  font-size: 30px;
  color: #000000;
  height: 100%;
  line-height: 88px;
  vertical-align: middle;
  width: 20%;
  margin: 0 10px;
  position: relative;
}
.nav a .dot {
  display: block;
  width: 0.4em;
  height: 0.4em;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 1vw;
  left: 50%;
  margin-left: 1.5em;
}
.nav a:visited {
  color: #000000;
}
.nav a:hover {
  color: #000000;
}
.nav a:active {
  color: #000000;
}
.nav span {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 64px;
}
.nav .on {
  height: 88px;
  line-height: 88px;
  color: #419cf7 !important;
  border-bottom: 2px solid #419cf7;
}
/* wrap .nav #nav_bt{display:none}*/

/*nav_con*/
.columns .hengtiao_game {
  border-bottom: 1px solid #e2dfdf;
  width: 100%;
  height: 132px;
  background: #ffffff;
}
.columns .hengtiao_game .game_box {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 94%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.columns .hengtiao_game .game_box .icon {
  margin-top: auto;
  margin-bottom: auto;
  width: 100px;
  height: 100px;
}
.columns .hengtiao_game .game_box .icon img {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.columns .hengtiao_game .game_box .jp {
  color: #ffffff;
  background: #ef4744;
  font-style: normal;
  font-size: 22px;
  padding: 2px 8px;
  position: absolute;
  top: -10px;
  right: 0px;
  border-radius: 10px;
}

/*detail*/
.columns .hengtiao_game .game_box .detail {
  display: block;
  margin-left: 16px;
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 1;
  height: 100px;
}
.columns .hengtiao_game .game_box .detail .name {
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
  border-color: #868585;
  color: #868585;
  line-height: 1;
  height: auto;
  display: flex;
  margin: 10px 0 10px 0;
}
.columns .hengtiao_game .game_box .detail .name span {
  font-size: 24px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333333;
  margin-right: 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.columns .hengtiao_game .game_box .detail .name .label {
  flex: 1;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
}
.columns .hengtiao_game .game_box .detail .name .label > i {
  display: inline-block;
  white-space: nowrap;
  font-size: 24px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 2px;
  margin: 0 5px 0;
  font-style: normal;
  overflow: hidden;
}
.columns .hengtiao_game .game_box .detail .name .type {
  font-size: 11px;
  color: #37bc9b;
  height: 14px;
  line-height: 14px;
  vertical-align: middle;
}
.columns .hengtiao_game .game_box .detail .msg {
  width: 450px;
  height: 44px;
  line-height: 44px;
  font-size: 24px;
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.columns .hengtiao_game .game_box .detail .msg span {
  font-size: 12px;
  color: #333;
}
.columns .hengtiao_game .game_box .detail .msg .type {
  color: #37bc9b;
}
.columns .hengtiao_game .game_box .detail .msg .time {
  color: #db4453;
}
.columns .hengtiao_game .game_box .detail .num {
  margin-top: 4px;
}
.columns .hengtiao_game .game_box .detail .num .num_text {
  display: inline-block;
  font-size: 13px;
  color: #db4453;
}
.columns .hengtiao_game .game_box .detail .num .jindutiao {
  display: inline-block;
  width: 92px;
  line-height: 13px;
  height: 13px;
  border: 1px solid #db4453;
}
.columns .hengtiao_game .game_box .detail .num .jindutiao .jindu {
  display: inline-block;
  line-height: 13px;
  height: 13px;
  background: #db4453;
}
.columns .hengtiao_game .game_box .detail .open_time {
  display: block;
  margin-top: 4px;
}
.columns .hengtiao_game .game_box .detail .open_time span {
  font-size: 26px;
}
.columns .hengtiao_game .game_box .detail .open_time .yjs_text {
  color: #bebebe;
}

/*button*/
.columns .hengtiao_game .game_box .button {
  position: relative;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  width: 116px;
  height: 56px;
  text-align: center;
  font-size: 0.8rem;
  line-height: 28px;
  color: #ffffff;
  background: #44a0fb;
  border-radius: 6px;
}
.columns .hengtiao_game .game_box .button a {
  position: absolute;
  display: block;
  width: 116px;
  height: 56px;
  font-size: 24px;
  line-height: 56px;
  left: 0;
  top: 0;
  color: #ffffff;
  white-space: nowrap;
}

.columns .hengtiao_game .game_box .yiyong {
  background: #dedddd;
}
.columns .hengtiao_game .game_box .bukeyong {
  background: #a1a2a2;
}
.columns .pr {
  position: relative;
}
</style>
